<template>
    <!-- 默认有类，允许自定义类 -->
    <div :class="[btnClass,cname]">
        <slot/>
    </div>
</template>
<script>
export default {
    name:"btn",
    props:{
        // 接收自定义的类名
        cname:{
            type:String,
            default:""
        }
    },
    data(){
        return {
            btnClass:'btn'   
        }
    }
}
</script>
<style lang="less" scoped>
    // @import "../../less/element.less";
    // .btn{
    //     .btnmin()
    // }
</style>
