<template>
  <div :options="options" class="header">
    <section>
      <router-link
        :to="{name: options.lefthref,query:{title:options.querytitle,pagenum:options.pagenum}}"
        v-if="options.lefthref"
      >
        <div class="head-left">
          <span :class="[iconClass,options.leftcname]"></span>
          <p>{{options.left}}</p>
        </div>
      </router-link>
      <div class="head-left" @click="$router.back()" v-else>
        <span :class="[iconClass,options.leftcname]"></span>
        <p>{{options.left}}</p>
      </div>
    </section>
    <slot />
    <router-link :to="{name: options.righthref}" v-if="options.rightcname">
      <div class="head-right">
        <span :class="[iconClass,options.rightcname]"></span>
        <p>{{options.right}}</p>
        <slot name="newsnum" />
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      iconClass: 'iconfont'
    };
  },
  props: {
    options: {
      lefthref: {
        type: String,
        default: ''
      },
      righthref: {
        type: String,
        default: ''
      },
      left: {
        type: String,
        default: ''
      },
      leftcname: {
        type: String,
        default: ''
      },
      right: {
        type: String,
        default: ''
      },
      rightcname: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      querytitle: {
        type: String,
        default: ''
      },
      pagenum: {
        type: String,
        default: ''
      }
    }
  }
};
</script>
<style lang="less" scoped>
.header {
  font-size: 1rem;
  padding: 0.4rem 0.8rem;
  font-weight: bolder;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  section {
    a {
      color: #ccc;
    }
  }
  .head-right {
    position: relative;
  }
}
</style>



