<template>
  <Heador :options="options" class="mydetail-header">
    <p>{{headertitle}}</p>
  </Heador>
</template>

<script>
import Heador from "../public/header";
export default {
  components: {
    Heador
  },
  data() {
    return {
      options: {
        leftcname: "icon-fanhui-copy",
        rightcname: "dada"
      }
    };
  },
  props: {
    headertitle: String
  }
};
</script>

<style lang='less' scoped>
.mydetail-header {
  background-color: #fafafa;
  color: #cccaca;
  line-height: 2rem;
  box-shadow: 0px 2px 9px #dad8d8;
  & > p {
    color: #d84b41;
  }
}
</style>
