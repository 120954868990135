<template>
  <div class="orderdetail">
    <transition name="fade">
      <preLoading v-if="isLoading"></preLoading>
    </transition>
    <!-- 头部 -->
    <!-- <Heador :headertitle="headertitle" /> -->
    <van-nav-bar
      title="订单详情"
      left-arrow
      @click-left="onClickLeft"
      fixed
      :z-index="101"
      class="dark_bg"
    />
    <!-- 身体 -->
    <div class='header-red'>
      <div>
        <div class='status-text'>{{ statustxt }}</div>
      </div>
      
    </div>
    <div class="corebody">
      <!-- 用户基本信息 -->

      <section @click="goAddress" v-if='orderDetailInfo.status === 10'>
        <span class="iconfont icon-dingwei"></span>
        <ul>
        
          <li>
            {{ orderDetailInfo.province }}{{ orderDetailInfo.city
            }}{{ orderDetailInfo.county }}
          </li>
          <li>{{ orderDetailInfo.address }}</li>
            <li>
            <span style='
            font-size: .24rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #7E7E7E;
            line-height: .3rem;
            '>{{ orderDetailInfo.consignee }}</span
            >&emsp;
            <span style='
            font-size: .24rem;
            font-family: SF Pro Text;
            font-weight: 500;
            color: #7E7E7E;
            line-height: .3rem;
            '>{{ orderDetailInfo.phone }}</span>
          </li>
        </ul>
        <span class="iconfont icon-qianjin" style='font-size:.24rem'></span>
      </section>

        
        <div class='status-deliver'  v-if='orderDetailInfo.status !== 10'>
          
          <img style='width:.26rem;height:.28rem;padding-right: .28rem;padding-bottom: .36rem' src='@/assets/images/order-cart.png'/>

         <ul style='padding-bottom: .46rem' class='status-deliver-top'>
           <li>
            <span style='
            font-size: .24rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333;
            line-height: .3rem;
            '>物流状态</span
            >
          </li>
        
          <li style='
          font-size: .24rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: .3rem;
          '>
          {{WaybillList ? '暂无物流信息' : '点击查看物流详情'}}
           
          </li>
            
          
        </ul>
        <span @click='goLogistics' v-if='!WaybillList' class="iconfont icon-qianjin" style='font-size:.24rem;padding-bottom: .46rem'></span>
        <span  v-else  style='font-size:.24rem;padding-bottom: .46rem;padding-right:.22rem'></span>

          
        <img style='width:.22rem;height:.28rem;padding-right: .28rem;' src='@/assets/images/addres-icon.png'/>

         <ul style='margin-left:-.22rem'>
           <li>
            <span style='
            font-size: .24rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333;
            line-height: .3rem;
            '>{{ orderDetailInfo.consignee }}</span
            >&emsp;
            <span style='
            font-size: .24rem;
            font-family: SF Pro Text;
            font-weight: 500;
            color: #333;
            line-height: .3rem;
            '>{{ orderDetailInfo.phone }}</span>
          </li>
        
          <li style='
          font-size: .24rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: .3rem;
          '>
            {{ orderDetailInfo.province }}{{ orderDetailInfo.city
            }}{{ orderDetailInfo.county }}{{ orderDetailInfo.address }}
          </li>
            
          
        </ul>
        <span  style='font-size:.24rem'></span>

        

      </div>
      
      

      <!-- <section class="dark_shop_bg">
        <span class="iconfont icon-dingwei"></span>
        <ul>
          <li>
            <span class="dark_text">{{ orderDetailInfo.consignee }}</span
            >&emsp;
            <span>{{ orderDetailInfo.phone }}</span>
          </li>
          <li>
            {{
              orderDetailInfo.province +
              orderDetailInfo.city +
              orderDetailInfo.county +
              orderDetailInfo.address
            }}
          </li>
          <li></li>
        </ul>
        <p>
          <span class="iconfont" :class="icon[statuscode]"></span>
          {{ statustxt }}
        </p>
      </section> -->
      <!-- 商品详情兑换内容-->
      <div class="productcore">
        <ul class="myorder-list">
          <li class="dark_shop_bg">
            <div
              v-for="(goods, gindex) in orderDetailInfo.skuList"
              :key="goods.id"
              @click="goGoodsDetail(goods.spuId + '/detail', '', '')"
            >
              <van-card
                :num="goods.num"
                :title="goods.spuTitle"
                :thumb="goods.img"
                :desc="goods.title"
                class="dark_shop_bg dark_text"
              >
                <span slot="price" style="color: #7d7e80"
                  >￥{{ (goods.price / 100).toFixed(2) }}</span
                >
                <div slot="footer" v-if="statuscode == 3">
                  <van-button
                    size="mini"
                    type="warning"
                    round
                    plain
                    @click.stop="goComment(gindex)"
                    >去评价</van-button
                  >
                </div>
              </van-card>
              <van-field
                label="所选服务点"
                type="textarea"
                rows="1"
                autosize
                :maxlength="50"
                disabled
                class="dark_shop_bg"
              >
                <span slot="input" v-html="goods.serviceName"></span>
              </van-field>
            </div>
            <section class="moneyInfo">
              <p>
                <span >总费用</span>
                <span style='color: #333333;'
                  >￥{{
                    (
                      (orderDetailInfo.actualPrice +
                        orderDetailInfo.couponPrice) /
                      100
                    ).toFixed(2)
                  }}</span
                >
              </p>
              <p>
                <span >运费</span>
                <span style='color: #333333;'
                  >￥{{ (orderDetailInfo.freightPrice / 100).toFixed(2) }}</span
                >
              </p>
              <!-- <p>
                <span>总积分</span>
                <span>{{ orderDetailInfo.score }}</span>
              </p> 
              <p
                v-if="
                  orderDetailInfo.internalScore != '0' &&
                  orderDetailInfo.internalScore != undefined
                "
              >
                <span>内购会积分</span>
                <span>{{ orderDetailInfo.internalScore }}</span>
              </p> -->
              <p>
                <span >代金劵抵用</span>
                <span style='color: #333333;'
                  > -￥{{ (orderDetailInfo.couponPrice / 100).toFixed(2) }}</span
                >
              </p>
              <p>
                <span >实际支付</span>
                <span class="showPriceStyle"
                  ><span style='font-size:.24rem'>￥</span>{{ (orderDetailInfo.actualPrice / 100).toFixed(2) }}</span
                >
              </p>
            </section>
          </li>
        </ul>
        <div class="order-info dark_shop_bg">
          <p style="color: black; margin-bottom: 0.3rem" class="dark_text">
            订单信息
          </p>
          <p>
            <span>订单编号：</span>
            <span class='bottom-two-color'>{{ orderDetailInfo.orderNo }}</span>
          </p>
          <p>
            <span>下单时间：</span>
            <span class='bottom-two-color'>{{ orderDetailInfo.gmtCreate }}</span>
          </p>
           <p>
            <span>支付方式：</span>
            <span class='bottom-two-color'>{{ orderDetailInfo.payChannel === 'WX' ? '微信支付' : '未知' }}</span>
          </p>
          <p>
            <span>支付时间：</span>
            <span class='bottom-two-color'>{{ orderDetailInfo.gmtUpdate }}</span>
          </p>
          <!-- <p>
            <span>订单备注：</span>
            <span>{{ orderDetailInfo.remark }}</span>
          </p> -->
          <!-- <p>
            <span>快递单号：</span>
            <span>{{ orderDetailInfo.logistics }}</span>
          </p>
          <p>
            <span>快递公司：</span>
            <span>{{ orderDetailInfo.logisticsCorp }}</span>
          </p> -->
        </div>
        <!-- 运单信息 -->
        <!-- <waybill-list :message="message"></waybill-list> -->
      </div>
    </div>
    <!-- 确认兑换 -->
    <div class="exchange-text">
      <!-- <p @click="goCustomerServer">
        <span class="iconfont icon-kefu"></span>客服
      </p> -->
      <p
          v-if="orderDetailInfo.status == 10 "
        @click="cancelOrder"
        class="inline-text order-btn"
        style='
        color: #AEAEAE;
        border: 1px solid #C0C0C0;
        display:flex;
        justify-content:center;
        '
      >
        <!-- <span class="iconfont icon-jianqu"></span> -->
        取消订单
      </p>
      <!-- <p v-if="orderDetailInfo.status == 10"> -->
      <p v-if="orderDetailInfo.status == 10">
        <Btn
          @click.native="goPay()"
          class='order-btn btn_style'
          >继续支付</Btn
        >
      </p>
      <Btn  
          class='order-btn'

          @click.native="Invoice(orderDetailInfo)"
          v-if="!invoiceId.invoiceId && (orderDetailInfo.status == 20 ||
          orderDetailInfo.status == 30 ||
          orderDetailInfo.status == 40 ||
          orderDetailInfo.status == 50)"
        
          >开具发票</Btn
        >
      <p
        v-if="
          orderDetailInfo.status == 20 ||
          orderDetailInfo.status == 22 ||
          orderDetailInfo.status == 30 ||
          orderDetailInfo.status == 40 ||
          orderDetailInfo.status == 50
        "
      >
        <Btn
          class='order-btn'
          @click.native="payRefundOrder(orderDetailInfo)"
          
          >申请退款</Btn
        >
      </p>
      <p v-if="orderDetailInfo.status == 30">
        <Btn
          class='order-btn btn_style'
          @click.native="finishOrder"
          >确认收货</Btn
        >
      </p>
      <!-- <p
        v-if="orderDetailInfo.status == 50"
        style="color: #cf4639"
        class="delete"
        @click="delOrder"
      >
        <span
          class="iconfont icon-shibai"
          style="font-size: 1.4rem; margin: 0 0 0.2rem"
        ></span
        >删除订单
      </p> -->
    </div>
  </div>
</template>

<script>
import Heador from "./header";
import Btn from "../common/btn";
import waybillList from "../public/waybillList";
import { Card, Button, Field, NavBar } from "vant";
export default {
  data() {
    return {
      headertitle: "订单详情",
      count: 1, // 积分数量
      orderDetail: [], // 积分信息
      goodsId: null, // 接收商品id
      icon: [
        "icon-weizhifu",
        "icon-wuliupeisong-",
        "icon-yunshuzhongwuliu-xianxing",
        "icon-yiwancheng",
        "icon-yiquxiao",
        "icon-shibai",
      ],
      statuscode: null, // 总状态值
      statustxt: null, // 总状态描述
      orderId: null, // 订单id
      orderDetailInfo: {},
      isLoading: true,
      goGoodsDetail: this.Base.goGoodsDetail,
      deviceEnvir: this.Base.deviceEnvir(), // 设备环境判断
      message: Object,
      invoiceId:'',
      WaybillList:false
    };
  },
  components: {
    Heador,
    Btn,
    [Card.name]: Card,
    [Field.name]: Field,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    waybillList,
  },
  mounted() {
    this.getOrderDetail();
    this.getWaybillList()
    this.invoiceId = JSON.parse(localStorage.invoiceId) 

    // this.$nextTick(() => {
    //   // this.size = document.documentElement.style.fontSize;
    //   this.size = window.getComputedStyle(document.documentElement)["fontSize"];
    //   console.log(this.size);
    //   let showsize = this.size.split("p")[0];
    //   console.log(showsize);

    //   document.documentElement.style.fontSize = showsize / 3.125 + "px";
    // });

    // this.initOrderList(0);
    let active = this.$route.query.active || "0";
    this.active = active;
  },
  // beforeDestroy() {
  //   document.documentElement.style.fontSize = this.size;
  // },

  methods: {
    // 开具发票
    Invoice(orderDetailInfo){
      console.log(orderDetailInfo);
      localStorage.setItem("commodity",JSON.stringify(orderDetailInfo))
      this.$router.push("/repairInvoice")
    },
    onClickLeft() {
      this.$router.back();
    },
    //获取运单
     getWaybillList() {
    
      this.$api.my
        .getOrderDelivery({
          userId: localStorage.getItem("customerId"),
          orderId: this.$route.params.orderNo,
        })
        .then((res) => {
          console.log(res);
          if(res.data){
            if(res.data.length >0){
              this.WaybillList = false
            }else{
               this.WaybillList = true
            }
          }
        });
    },
    getOrderDetail() {
      localStorage.orderNo = this.$route.params.orderNo || localStorage.orderNo;

      this.$api.my
        .orderSureAddress({
          orderId: this.$route.params.orderNo || localStorage.orderNo,
          userId: localStorage.getItem("customerId"),
        })
        .then((res) => {
          if (res.errno === 200) {
            this.orderDetailInfo = res.data;
            this.orderDetailInfo.gmtCreate = this.formatDate(
              this.orderDetailInfo.gmtCreate
            );
            this.orderDetailInfo.gmtUpdate = this.formatDate(
              this.orderDetailInfo.gmtUpdate
            );
            sessionStorage.orderNoID = res.data.orderNo;
            sessionStorage.setItem(
              "orderAddress",
              this.orderDetailInfo.province +
                this.orderDetailInfo.city +
                this.orderDetailInfo.county +
                this.orderDetailInfo.address
            );

            // this.orderDetailInfo.discountMoney =
            //   res.returnValue.discountMoney + ".00";
            // sessionStorage.setItem("orderAddress", res.returnValue.address);
            // this.statuscode = res.returnValue.generalStatus;
            let tabarr = [
              "全部",
              "待付款",
              // "等待团购活动结束",
              "待出库",
              // "配货异常",
              "待收货",
              "待评价",
              "完成",
              "退款中",
              "已退款",
              "支付超时",
              "支付异常",
            ];
            let arrnum = [undefined, 10, 20, 30, 40, 50, 60, 70, 90, 100];
            arrnum.forEach((v, i) => {
              if (v === res.data.status) {
                this.statustxt = tabarr[i];
              }
            });
            this.orderStatus = res.data.status
            // this.orderDetail = res.returnValue.listGoodsOrderDetailVO;
            // this.orderId = res.returnValue.id;
            this.isLoading = false;
          }
        });
    },

     // 把订单id传过去
    goAddress() {
      // this.$router.push({
      //   path: "/addresslist",
      //   query: {
      //     // orderId: this.$route.params.orderId,
      //     orderId: "1",
      //   },
      // });
    },

    formatDate(cellValue) {
      if (cellValue == null || cellValue == "") return "";
      var date = new Date(cellValue);
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    // 支付逻辑，最终与后台交互
    goPay() {
      let orderList = [];
      this.orderDetailInfo.skuList.forEach((val, i) => {
        // if (item.skuList.indexOf(val.id) != -1) {
        orderList.push({
          id: val.id,
          img: val.img, // 商品图片
          title: val.spuTitle, // 商品标题
          skuTitle: val.title, // 商品规格
          num: val.num, //商品数量
          price: val.price, //商品价格
          skuId: val.skuId, //商品规格id
          spuId: val.spuId, //商品id
          isshowcar: false,
          orderNo:val.orderNo,
          takeWay: "cart",
        });
        // }
      });

      localStorage.setItem("skuGoodsList", JSON.stringify(orderList));
      // console.log(
      //   "购物车 取skuGoodsList",
      //   JSON.parse(localStorage.getItem("skuGoodsList"))
      // );
      this.$router.push({
        path: "/orderconfirm",
        query: {
          orderStatus: this.orderStatus
        }
      });

      // this.$router.push(`/orderconfirm/${id}`);
    },

    goLogistics(){
      // console.log(99);
      // console.log( this.orderDetailInfo.skuList[0].orderNo);
      this.$router.push({ name: "logisticsDetails", params: { orderNo:this.orderDetailInfo.skuList[0].orderId } });

    },

    // goPay() {
    //   let orderId = this.$route.params.orderId;
    //   // H5支付,(从德合汇跳转到云楚新能源属于H5支付)
    //   const isDehe = localStorage.getItem("isDehe");
    //   // const isDehe = '1';
    //   const openid = localStorage.getItem("openid");
    //   const isHuawei = localStorage.getItem("isHuawei");
    //   const isSalesman = localStorage.getItem("isSalesman");
    //   // d德合汇跳过来的就H5支付
    //   if (isDehe || isHuawei) {
    //     this.$server
    //       .getH5paramsAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           this.goPayResult();
    //           switch (this.deviceEnvir) {
    //             case "IOS":
    //               window.webkit.messageHandlers.invokeiOS.postMessage({
    //                 cmd: "010",
    //                 mweb_url: res.returnValue,
    //               });
    //               break;
    //             case "ANDROID":
    //               window.location.href = res.returnValue;
    //               break;
    //           }
    //         } else {
    //           this.$toast(res.message);
    //           this.goPayResult();
    //         }
    //       });
    //     return false;
    //   }
    //   // 理财师跳过来的就H5支付
    //   if (isSalesman) {
    //     this.$server
    //       .getH5paramsAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           this.goPayResult();
    //           switch (this.deviceEnvir) {
    //             case "IOS":
    //               window.webkit.messageHandlers.invokeiOS.postMessage({
    //                 cmd: "010",
    //                 mweb_url: res.returnValue,
    //               });
    //               break;
    //             case "ANDROID":
    //               window.location.href = res.returnValue;
    //               break;
    //           }
    //         } else {
    //           this.$toast(res.message);
    //           this.goPayResult();
    //         }
    //       });
    //     return false;
    //   }
    //   // 公众号支付
    //   if (openid) {
    //     // 走公众号支付和充值卡
    //     this.$server
    //       .payAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //         openid: localStorage.getItem("openid") || "",
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           // 代表直接用充值卡就支付完毕
    //           if (res.returnValue == 1) {
    //             this.goPayResult();
    //           } else {
    //             // 充值卡余额不够，走了微信支付
    //             let params = { ...res.returnValue }; // 接收后台的返回值
    //             if (typeof WeixinJSBridge === "undefined") {
    //               if (document.addEventListener) {
    //                 document.addEventListener(
    //                   "WeixinJSBridgeReady",
    //                   onBridgeReady,
    //                   false
    //                 );
    //               } else if (document.attachEvent) {
    //                 document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
    //                 document.attachEvent(
    //                   "onWeixinJSBridgeReady",
    //                   onBridgeReady
    //                 );
    //               }
    //             } else {
    //               this.onBridgeReady(params);
    //             }
    //             this.goPayResult();
    //           }
    //         } else {
    //           this.$toast(res.message);
    //         }
    //       });
    //     return false;
    //   }
    //   // APP支付
    //   if (this.deviceEnvir === "IOS") {
    //     // IOS支付
    //     // 获取支付需要的参数
    //     this.$server
    //       .getParamsAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           if (res.returnValue == 1) {
    //             // 直接全部走充值卡支付
    //             this.goPayResult();
    //           } else {
    //             let params = res.returnValue;
    //             // 向IOS发送指令
    //             const paramsObj = { cmd: "009", ...params };
    //             window.webkit.messageHandlers.invokeiOS.postMessage(paramsObj);
    //             this.goPayResult();
    //           }
    //         } else {
    //           this.$toast(res.message);
    //         }
    //       });
    //     return false;
    //   } else if (this.deviceEnvir === "ANDROID") {
    //     // 安卓支付
    //     // 获取支付需要的参数
    //     this.$server
    //       .getParamsAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           if (res.returnValue == 1) {
    //             this.goPayResult();
    //             return false;
    //           } else {
    //             let params = res.returnValue;
    //             // 向安卓发送指令
    //             let paramsStr = JSON.stringify({ cmd: "009", ...params });
    //             window.android.invokeAndroid(paramsStr);
    //             this.goPayResult();
    //           }
    //         } else {
    //           this.$toast(res.message);
    //         }
    //       });
    //     return false;
    //   }
    // },
    // 去到询问支付界面
    goPayResult() {
      setTimeout(() => {
        this.$router.replace("/payResult");
      }, 3000);
    },
    // 调起微信支付
    onBridgeReady(params) {
      let that = this;
      WeixinJSBridge.invoke("getBrandWCPayRequest", params, function (res) {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          that.$router.replace("/myorder");
        } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
          this.goPayResult();
        } else {
          that.$dialog
            .confirm({
              message: "支付失败",
              showCancelButton: false,
            })
            .then(() => {
              this.goPayResult();
            });
        }
      });
    },
    // 去评价
    goComment(index) {
      this.$router.push(`/addComment/${this.$route.params.orderId}/${index}`);
    },
    // 取消订单
    cancelOrder() {
      // let accessToken = localStorage.getItem("token");
      this.$api.my
        .cancelOrderAddress({
          accessToken: localStorage.getItem("token"),
          orderNo: sessionStorage.getItem("orderNoID"),
        })
        .then((res) => {
          if (res.errno == 200) {
            this.$toast({
              message: "取消成功",
              duration: 700,
              onClose: () => {
                this.$router.replace({
                  path: "/myorder",
                  query: {
                    active: "5",
                  },
                });
              },
            });
          } else {
            this.$toast(res.errmsg);
          }
        });

      // this.$server
      //   .cancelOrderAddress({
      //     accessToken,
      //     orderId: this.orderId,
      //   })
      //   .then((res) => {
      //     if (res.returnValue) {
      //       this.$toast({
      //         message: "取消成功",
      //         duration: 1000,
      //         onClose: () => {
      //           this.$router.replace({
      //             path: "/myorder",
      //             query: {
      //               active: "5",
      //             },
      //           });
      //         },
      //       });
      //     } else {
      //       this.$toast(res.message);
      //     }
      //   });
    },
    // 删除订单
    // delOrder() {
    //   this.$server
    //     .delOrderAddress({
    //       accessToken: localStorage.getItem("token"),
    //       orderId: this.orderId,
    //     })
    //     .then((res) => {
    //       if (res.returnValue) {
    //         this.$toast.success({
    //           message: "删除成功",
    //           duration: 1000,
    //           onClose: () => {
    //             this.$router.replace("/myorder");
    //           },
    //         });
    //       } else {
    //         this.$toast(res.message);
    //       }
    //     });
    // },
    // 申请退款
    payRefundOrder(orderId) {
       this.$router.push({
        name: "salesReturn",
        params: {
          orderId,
        },
      });
      // this.$api.my
      //   .payRefundOrderAddress({
      //     accessToken: localStorage.getItem("token"),
      //     orderNo: sessionStorage.getItem("orderNoID"),
      //     userId: localStorage.getItem("customerId"),
      //   })
      //   .then((res) => {
      //     if (res.errno === 200) {
      //       this.$toast.success({
      //         message: "申请退款成功",
      //         duration: 700,
      //         onClose: () => {
      //           this.$router.replace("/myorder");
      //         },
      //       });
      //     } else {
      //       this.$toast(res.errmsg);
      //     }
      //   });
      // this.$server
      //   .payRefundOrderAddress({
      //     accessToken: localStorage.getItem("token"),
      //     orderId: this.orderId,
      //   })
      //   .then((res) => {
      //     if (res.code === 100 && res.returnValue) {
      //       this.$toast.success({
      //         message: "申请退款成功",
      //         duration: 700,
      //         onClose: () => {
      //           this.$router.replace("/myorder");
      //         },
      //       });
      //     } else {
      //       this.$toast(res.message);
      //     }
      //   });
    },
    // 完成订单
    finishOrder() {
      this.$api.my
        .orderFinishAddress({
          accessToken: localStorage.getItem("token"),
          orderNo: sessionStorage.getItem("orderNoID"),
        })
        .then((res) => {
          if (res.errno === 200) {
            this.$toast("订单完成");

            this.$router.replace("/myorder");
          } else {
            this.$toast(res.errmsg);
          }
        });
      // .then((res) => {
      //   if (res.code === 100 && res.returnValue === 1) {
      //     this.$toast("订单完成");
      //   } else {
      //     this.$toast(res.message);
      //   }
      // });
    },
    // 去到客服
    // goCustomerServer() {
    //   this.$router.push({
    //     path: "/customerChat",
    //     query: { orderId: this.$route.params.orderId },
    //   });
    // },
  },
  computed: {
    total() {
      var moneyTotal = 0,
        scoreTotal = 0;
      this.orderDetail.forEach((v, i) => {
        moneyTotal += v.amount * v.money;
        scoreTotal += v.amount * v.score;
      });
      return `￥${moneyTotal}+${scoreTotal}积分`;
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../less/element.less";
@red: #cf4639;

.orderdetail {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0.76rem 0 0rem ;
  background-color: #f0eded;

  .van-nav-bar {
    .van-icon {
      color: #ccc;
      font-size: 0.44rem;
    }
    .van-nav-bar__title {
      color: #d84b41;
      letter-spacing: 2px;
    }
  }
  .corebody {
    margin-top: -.9rem;
    padding: 0.3rem;
    & > section {
      padding: 0.32rem 0.32rem;
      background: #fff;
      margin-bottom: 0.2rem;
      border-radius: 0.06rem;

      box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05);
      border-radius: .2rem;

      .list(row);
      justify-content: space-between;
      align-items: center;
      ul {
        width: 70%;
        li {
          font-size: 0.28rem;
          line-height: 0.44rem;
          &:first-child {
            span {
              &:first-child {
                color: black;
              }
              &:last-child {
                color: rgb(156, 155, 155);
              }
            }
          }
          &:last-child {
            color: @ordinary_icon_color;
            font-size: 0.28rem;
          }
        }
      }
      & > span {
        font-size: 0.64rem;
        color: @dingwei_icon_color;
      }
      & > p {
        font-size: 0.24rem;
        text-align: center;
        color: @ordinary_text_color;
        span {
          display: block;
          font-size: 0.5rem;
          line-height: 0.64rem;
        }
      }
    }
    &>.status-deliver{
      display: flex;
       padding: 0.32rem 0.32rem;
      background: #fff;
      margin-bottom: 0.2rem;
      border-radius: 0.06rem;
      position: relative;

      box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05);
      border-radius: .2rem;
      flex-wrap: wrap;

      .status-deliver-top::after{
        position: absolute;
        top: 1.36rem;
        left:.3rem;
        content: '';
        width: 6.3rem;
        height: 1px;
        background: #EFEFEF;
      }

      .list(row);
      justify-content: space-between;
      align-items: center;
      ul {
        width: 86%;
        li {
          font-size: 0.28rem;
          line-height: 0.44rem;
          &:first-child {
            span {
              &:first-child {
                color: black;
                
              }
              &:last-child {
                color: rgb(156, 155, 155);
              }
            }
          }
          &:last-child {
            color: @ordinary_icon_color;
            font-size: 0.28rem;
          }
        }
      }
      & > span {
        font-size: 0.64rem;
        color: @dingwei_icon_color;
      }
      & > p {
        font-size: 0.24rem;
        text-align: center;
        // color: #e8724a;
        span {
          display: block;
          font-size: 0.5rem;
          line-height: 0.64rem;
        }
      }
    }
    & > .productcore {
     
      .myorder-list {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        
         margin-bottom: 0.2rem;

      box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05);
      border-radius: .2rem;
      overflow: hidden;

        li {
          background-color: #fff;
          border-radius: 0.06rem;
          .van-card {
            background: #fff;
            border-radius: 0.06rem 0.06rem 0 0;
            padding-top: .4rem ;
          }
          .van-cell {
            background: #fff;
            font-size: 0.24rem;
          }
          .moneyInfo {
            padding: 0.12rem 0.24rem .4rem;
            font-size: 0.24rem;
            p {
              .list(row);
              // line-height: 0.44rem;
              justify-content: space-between;

              font-size: .28rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: #7E7E7E;
              line-height: .5rem;
            }
          }
        }
      }
      & > div.order-info {
        // margin: 0 0 0.32rem;
        padding: 0.32rem 0.24rem;
        background: #fff;
        box-sizing: border-box;
        // border-radius: 0.06rem;

         box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05);
          border-radius: .2rem;
        p {
          .list(row);
          line-height: 0.44rem;
          font-size: 0.32rem;
          color: rgb(139, 138, 138);
          &:first-child::before {
            display: inline-block;
            width: 2px;
            height: 14px;
            content: "";
            // background-color: #cd4033;
            margin-right: 0.16rem;
          }
        }
        & > p:not(:first-child) {
          font-size: 0.28rem;
          text-indent: 16px;
          margin-bottom: .4rem;

          font-family: PingFang SC;
          font-weight: 500;
          color: #7E7E7E;
          line-height: .5rem;
            .bottom-two-color{
                font-size: .28rem;
                font-family: SF Pro Text;
                font-weight: 500;
                color: #333333;
            }

          span {
            &:first-child {
              color: rgb(83, 83, 83);
            }
          }
        }
      }
      & > p {
        margin: 0.32rem 0 0;
        padding: 0.32rem 0 0;
        border-top: 1px solid #ccc;
        span {
          color: @ordinary_text_color;
        }
      }
    }
  }
  & > .exchange {
    .list(row);
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    z-index: 99;
    padding: 0.38rem;
    // justify-content: space-between;
    justify-content: space-around;
    align-items: center;
    background-color: #f0ebe8;
    bottom: 0;
    left: 0;
    p {
      color: @red;
      font-size: 0.24rem;
      letter-spacing: 1px;
      margin: 0 0 0 0.24rem;
      &:first-child {
        span {
          display: block;
          font-size: 0.53rem;
        }
      }
      &:nth-child(2) {
        color: rgb(241, 148, 117);
        span {
          font-size: 0.53rem;
          text-align: center;
          display: block;
        }
      }
      &:last-child {
        .btn {
          .btnmin(
            @padding:0.24rem 1.6rem,
            @size:0.28rem,
            @radius:0.06rem,
            @color:#fff
          );
          background: linear-gradient(to right, #e8724a, #d04435);
        }
      }
    }
  }
}
.showPriceStyle { 
  color: @price_text_color;
  font-size:.36rem;
}
</style>
<style lang='less'>
.addcardMask {
  .van-dialog__header {
    padding-left: 0.53rem;
    text-align: left;
    color: #7d7e80;
  }
  .van-dialog__confirm {
    // color: #c83f3f;
  }
}
.inline-text {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.delete {
  display: flex;
  align-items: center;
}
.exchange-text {
  position: absolute;
  // bottom: 0;
  left: 0;
  width: 100%;
  height: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
    background: #FFFFFF;

  .order-btn{
    margin-right: .3rem;
    width: 1.8rem;
    height: .7rem;
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    border-radius: .1rem;
    line-height: .7rem;
    text-align: center;

    font-size: .3rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }
}
.van-card__price {
  font-size: 0.28rem;
}
.header-red{
  height: 2rem;
  background: @header_background;  
}
.status-text{
  padding:.6rem 0 0 .7rem;
  font-size: .32rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: .4rem;
}
.btn_style {
  background: @ordinary_btn_color !important;
  color: #fff !important;
  border: @ordinary_btn_border_color !important;
}
  .van-field--disabled .van-field__label{
    color:#323233
  }
</style>
