<template>
  <!-- 运单列表信息 -->
  <div class="waybill" v-if="waybillList.length">
    <p style="font-size: 0.3rem">运单信息</p>
    <section>
      <ul
        v-for="item in waybillList"
        :key="item.id"
        class="waybill-list"
        @click="goTransDetail(item)"
      >
        <li>
          <span>运单编号：</span>
          <span>{{ item.shipNo }}</span>
        </li>
        <li>
          <span>快递公司：</span>
          <span>{{ item.shipCodeName }}</span>
        </li>
        <li class="img-list">
          <p class="imgs">
            <img
              :src="img"
              alt="运单商品图片"
              v-for="(img, imgi) in item.imgList"
              :key="imgi"
            />
          </p>
          <p class="trans-enter">查看物流></p>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      waybillList: [], // 运单列表
    };
  },

  created() {
    this.getWaybillList();
  },
  methods: {
    // 获取运单列表

    getWaybillList() {
      // 快递列表
      let supplier = [
        { id: "SFEXPRESS", name: "顺丰速运" },
        { id: "HTKY", name: "百世快递" },
        { id: "ZTO", name: "中通快递" },
        { id: "STO", name: "申通快递" },
        { id: "YTO", name: "圆通速递" },
        { id: "YUNDA", name: "韵达速递" },
        { id: "CHINAPOST", name: "邮政快递包裹" },
        { id: "EMS", name: "EMS" },
        { id: "TTKDEX", name: "天天快递" },
        { id: "JD", name: "京东快递" },
        { id: "UC56", name: "优速快递" },
        { id: "DEPPON", name: "德邦快递" },
        { id: "ZJS", name: "宅急送" },
        { id: "TNT", name: "TNT快递" },
        { id: "QFKD", name: "全峰物流" },
        { id: "SUNING", name: "苏宁" },
        { id: "PJKD", name: "品骏" },
        { id: "YUNDA56", name: "韵达快运" },
        { id: "SXJD", name: "顺心捷达" },
        { id: "BSKY", name: "百世快运" },
        { id: "KKE", name: "京广速递" },
        { id: "JITU", name: "极兔速递" },
        { id: "ZTO56", name: "中通快运" },
      ];
      this.$api.my
        .getOrderDelivery({
          userId: localStorage.getItem("customerId"),
          orderId: this.$route.params.orderNo,
        })
        .then((res) => {
          if (res.errno === 200) {

            this.waybillList = res.data;
            console.log( this.waybillList);

            for(let i = 0 ; i<this.waybillList.length ; i++){

              supplier.forEach((v) => {
             
              if ((v.id === this.waybillList[i].shipCode)) {
                 this.waybillList[i].shipCodeName = v.name;
              }
            });


            }

          
          }
        });
    },
    // 去物流详情
    goTransDetail(deliveryId) {
      console.log(deliveryId);
      this.$router.push({
        name: "logistics",
        params: {
          item: deliveryId,
          orderNo:this.$route.params.orderNo
        },
      });
      // this.$router.push(`/logistics/${deliveryId}`);
    },
  },
};
</script>

<style lang="less" scoped>
.waybill {
  padding: 1/3.125rem 0.8/3.125rem;
  background: #fff;
  box-sizing: border-box;
  border-radius: .1rem;
  box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05);

  & > p {
    line-height: 1.4/3.125rem;
    //     margin-bottom: 1/3.125rem;
    color: black;
    &::before {
      display: inline-block;
      width: 2px;
      height: 13px;
      content: "";
      background-color: #cd4033;
      margin-right: 0.5/3.125rem;
    }
  }
  & > section {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 0 1/3.125rem;
    ul {
      &:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
      }
      margin-top: 1/3.125rem;
      li {
        line-height: 1.6/3.125rem;
        font-size: 0.9/3.125rem;
        color: #8b8a8a;
        &.img-list {
          display: flex;
          align-items: flex-end;
          margin: 0.5/3.125rem 0 1/3.125rem;
          p {
            &.imgs {
              flex: 4;
              img {
                width: 23%;
                border-radius: 0.3/3.125rem;
                margin: 3px 3px 0 0;
              }
            }
            &.trans-enter {
              flex: 1;
              color: #cd4033;
              font-size: 0.7/3.125rem;
            }
          }
        }
        span:first-child {
          color: #535353;
        }
      }
    }
  }
}
</style>